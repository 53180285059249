@import '../../../../App.scss';

.countries-table {
    max-height: 250px;
    overflow: auto;

    h2 {
        background-color: rgba(5, 5, 82, 0.03);
        padding: 0.5em;

    }

    table {
        border-collapse: collapse;
        width: 100%;
        text-align: left;
        border-radius: 5px;
        border: solid 0.5px rgba(5, 5, 82, 0.1);
        background-color: var(--border-grey);

        th,
        td {
            padding: 0.5em 0;
            height: 39px;
            border-bottom: 1px solid #ddd;
            width: fit-content;
        }

        th {
            margin-bottom: 1em;
            font-style: normal;
            font-weight: 500;
            font-size: 12px;
            line-height: 15px;
            /* identical to box height */
            font-family: $font_family;
            color: rgba(5, 5, 82, 0.8);
            background: rgba(5, 5, 82, 0.03);
        }

        td {
            font-style: normal;
            font-weight: 400;
            font-size: 12px;
            line-height: 15px;
            /* identical to box height */

            letter-spacing: 0.01em;
            font-family: $font_family;
            color: rgba(5, 5, 82, 0.6);
        }

        .flag-column {
            width: 30px;
        }
    }

    &__countries-flag {
        width: 28px;
        height: 24px;
        border-radius: 2px;
        margin-right: 16px;
        margin-left: 8px;

    }
}