@import '../../App.scss';


.font-picker {
    background-color: rgb(255, 255, 255);
    width: 100%;
    font-family: $font_family;
    height: 32px;
    border: 0.5px solid rgba(5, 5, 82, 0.20);
    border-bottom: none;
    color: rgba(5, 5, 82, 0.80);
    display: flex;
    align-items: center;
    font-size: 12px;


    &_label {
        display: flex;
        justify-content: center;
        align-items: center;
        font-size: 14px;
        flex-grow: 1;
    }

    select {
        font-family: $font_family;
        color: rgba(5, 5, 82, 0.80);
        font-size: 14px;
        text-overflow: ellipsis;
        background-image: url("../../../public/fontDropDown.svg");
        background-repeat: no-repeat;
        background-position: calc(100% - 10px) center;
        appearance: none;
        border: none;
        width: 100%;
        max-width: 100px;
        padding-right: 20px;

        &:focus {
            outline: none;
        }

        &::-ms-expand {
            display: none;
        }

        &::after {
            content: "";
            position: absolute;
            top: 50%;
            right: 4px;
            transform: translateY(-50%);
            width: 0;
            height: 0;
            border-style: solid;
            border-width: 5px 5px 0;
            border-color: #333 transparent transparent;
            pointer-events: none;
        }
    }
}