@import '../../../../App.scss';

.duration {
  display: flex;
  align-items: center;
  justify-content: space-between;
  margin-bottom: 16px;

  &_disabled &-label,
  &_disabled &-input,
  &_disabled &-dropdown {
    font-style: italic !important;
    color: rgba(5, 5, 82, 0.4) !important;
  }

  &-label {
    font-family: $font_family;
    font-style: normal;
    font-weight: 500;
    font-size: 12px;
    line-height: 20px;
    color: rgba(5, 5, 82, 0.8);
  }

  &-input {
    width: 39px;
    height: 32px;
    background: #FFFFFF;
    border: 1px solid rgba(5, 5, 82, 0.1);
    border-radius: 5px;
    text-align: center;
    font-family: $font_family;
    font-style: normal;
    font-weight: 400;
    font-size: 12px;
    line-height: 15px;
    color: rgba(5, 5, 82, 0.8);
    margin-right: 8px;
  }

  &-dropdown {
    padding: 8px;
    font-size: 14px;
    line-height: 20px;
    color: #333333;
    background-color: #FFFFFF;
    width: 71px;
    height: 32px;
    border: 1px solid rgba(5, 5, 82, 0.1);
    border-radius: 5px;
    font-family: $font_family;
    font-style: normal;
    font-weight: 400;
    font-size: 12px;
    line-height: 15px;
    appearance: none;
    background: url('../../../../assests/icons/dropDownIcon.svg') no-repeat right 14px bottom 12px;
    color: rgba(5, 5, 82, 0.8);
  }

  &-details-flex {
    display: flex;
  }

}