@import '../../containers/FormModule/FormModuleDrawer.scss';

$chrome-picker-width: 225px;
$color-input-width: 129px;

.color-picker {
    display: grid;
    margin-bottom: 24px;
    margin-top: 24px;
    margin-left: 64px;
    width: 60%;

    &__container {
        display: flex;
        flex-direction: row;
        width: 100%;
        justify-content: space-between;
        position: relative;
        cursor: pointer;
    }

    &__label {
        margin-right: 54px;
        color: rgba(5, 5, 82, 0.80);
        font-family: Inter;
    }

    &__subcontainer {
        display: flex;
        flex-direction: row;
    }

    &__preview {
        width: 24px;
        height: 31px;
        box-shadow: 0 1px 2px 0 rgba(5, 5, 82, 0.06);
        border: solid 1px rgba(5, 5, 82, 0.1);
        border-right: none;
        border-radius: 5px 0px 0px 5px;
    }

    &__chrome-picker {
        position: absolute;
        z-index: 9999999999999 !important;
        top: 100%;
        right: 0;
        transform: translateX(calc($chrome-picker-width - $color-input-width));
    }
}

.button-color-picker {
    display: flex;
    margin-right: 0px;
    margin-bottom: 8px;
    width: 100%;
    flex-direction: row;
    gap: 4px;

    &__container {
        display: flex;
        flex-direction: row;
        width: 100%;
        justify-content: space-between;
        align-items: center;
        position: relative;
        cursor: pointer;
    }

    &__label {
        // @extend .edit-properties-div__label;
    }

    .color-picker__chrome-picker {
        transform: translateX(0);
    }
}

.color-input {
    box-shadow: 0 1px 2px 0 rgba(5, 5, 82, 0.06);
    border: solid 1px rgba(5, 5, 82, 0.1);
    width: 105px;
    height: 31px;
    padding: 12px;
    color: rgba(5, 5, 82, 0.80);

    &:focus {
        outline: none;
    }
}