.common_spinner {
    border-radius: 50%;
    -webkit-animation: spin 0.75s linear infinite;
    animation: spin 0.75s linear infinite;

    &.small {
        border: 1.5px solid rgba(5, 5, 82, 0.2);
        border-top: 1.5px solid rgba(5, 5, 82, 1);
        width: 26px;
        height: 26px;

    }

    &.meduim {
        border: 3px solid rgba(5, 5, 82, 0.2);
        border-top: 3px solid rgba(5, 5, 82, 1);
        width: 52px;
        height: 52px
    }

    &.big {
        border: 4.5px solid rgba(5, 5, 82, 0.2);
        border-top: 4.5px solid rgba(5, 5, 82, 1);
        width: 78px;
        height: 78px
    }
}

/* Safari */
@-webkit-keyframes spin {
    0% {
        -webkit-transform: rotate(0deg);
    }

    100% {
        -webkit-transform: rotate(360deg);
    }
}

@keyframes spin {
    0% {
        transform: rotate(0deg);
    }

    100% {
        transform: rotate(360deg);
    }
}