.single-select-dropdown {
  width: 100%;
  position: relative;


  &__dropdown-header {
    display: flex;
    justify-content: space-between;
    align-items: center;
    cursor: pointer;
    width: 360px;
    padding: 9px 12px 8px;
    border-radius: 5px;
    border: solid 1px rgba(5, 5, 82, 0.1);
    transition: border-color 0.3s;
    background: none;
    font-family: Inter;
    font-size: 12px;
    font-weight: normal;
    height: 32px;

    &.open {
      border-color: blue;
    }

    i {
      margin-left: 10px;
      transition: transform 0.3s;

      &.open {
        transform: rotate(180deg);
      }
    }
  }

  &__dropdown-menu {
    list-style: none;
    padding: 4px 0px;
    margin: 0;
    border-radius: 5px;
    box-shadow: 0 5px 15px 0 rgba(5, 5, 82, 0.1);
    border: solid 0.5px rgba(5, 5, 82, 0.1);
    background-color: white;
    max-height: 240px;
    overflow-y: scroll;
    width: 360px;
    z-index: 9;
    position: absolute;
    top: 32px;

    li {
      button {
        padding: 10px;
        cursor: pointer;
        transition: background-color 0.3s;
        background: none;
        border: none;
        width: 100%;
        font-family: Inter;
        font-size: 12px;
        color: rgba(5, 5, 82, 0.8);
        text-align: left;

        &:hover {
          background-color: rgba(85, 78, 241, 0.1);
        }
      }
    }
  }

  &_override {
    position: relative;
    top: 0;
  }
}