@import "../../App.scss";

.fallback {
  display: flex;
  align-items: center;
  flex-direction: column;
  width: 100vw;
  height: 100vh;


  &__heading {
    font-size: 60px;
  }

  &__text {
    font-family: $font_family;
    height: 80px;
    display: flex;
    align-items: center;
  }

  &__button {
    // border: none;
    width: 200px;
    height: 40px;
    padding: 8px 12px;
    border-radius: 5px;
    border: solid 1.2px #554ef1;
    font-size: 12px;
    display: flex;
    align-items: center;
    justify-content: center;
    cursor: pointer;
    font-weight: 600;
    background: #554ef1;
    color: white;

    &:hover {
      border: solid 1.2px #9590fa;
      background: #9590fa;
      color: white;
    }
  }
}