@import '../../App.scss';

.text-input-div {
    padding: 4px;
    width: 100%;
    font-family: $font_family;
    padding-left: 0px;
    font-size: 12px;
    font-weight: normal;
    font-stretch: normal;
    font-style: normal;
    line-height: normal;
    letter-spacing: normal;
    text-align: left;
    color: rgba(5, 5, 82, 0.8);

    &__text-input {
        width: 100%;
        height: 31px;
        padding: 8px;
        border-radius: 6px;
        background-color: transparent;
        box-shadow: 0 1px 2px 0 rgba(5, 5, 82, 0.06);
        border: solid 1px rgba(5, 5, 82, 0.1);
        font-family: $font_family;
        font-size: 12px;
        color: #050552;

        &:focus {
            outline: solid 1px #554ef1;
            background-color: none;
        }

        .MuiInputBase-formControl {
            height: calc(100% + 16px);
            margin: -8px;

            .MuiOutlinedInput-input {
                font-size: 12px;
            }

            &:not(.Mui-error) .MuiOutlinedInput-notchedOutline {
                border: none;
            }

            &.Mui-error .MuiOutlinedInput-notchedOutline {
                border: solid 1px #f79191e5;
            }

            &:not(.Mui-error) .MuiOutlinedInput-input:focus+.MuiOutlinedInput-notchedOutline {
                border: solid 1px #554ef1;
            }

            &.Mui-error .MuiOutlinedInput-input:focus+.MuiOutlinedInput-notchedOutline {
                border: solid 1px #db0202;
            }
        }

        .MuiFormHelperText-root {
            margin-inline: 4px;
            margin-top: 8px;
        }
    }

    &__text-input-invalid {
        width: 100%;
        height: 31px;
        padding: 8px;
        border-radius: 6px;
        background-color: none;
        box-shadow: 0 1px 2px 0 rgba(5, 5, 82, 0.06);
        border: solid 1px rgba(242, 29, 29, 0.1);
        font-family: $font_family;
        font-size: 12px;
        color: #050552;

        &:focus {
            outline: solid 1px #f14e4e;
            background-color: none;
        }
    }

}