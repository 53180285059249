@import '../../App.scss';

.add-logo-div {
    font-family: $font_family;
    color: rgba(5, 5, 82, 0.8);
    display: flex;
    justify-content: space-around;

    &__name {}

    &__upload-button {}
}