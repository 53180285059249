.text_input {
    height: 2rem;
    width: 100%;
    font-family: "Inter";
    font-size: 0.75rem;
    text-align: left;
    border: solid 1px rgba(5, 5, 82, 0.1);
    border-radius: 0.375rem;
    display: flex;
    align-items: center;
    justify-content: space-between;
    padding-right: 8px;
    cursor: pointer;
    background-color: white;

    &__box {
    border: none;
    height: 100%;
    width: 100%;
    align-items: center;
    border-radius: 0.375rem;
    background-color: inherit;
    padding-left: 0.5rem;
    border-radius: 0.375rem;
    margin-right: 2px;
    color: rgba(5, 5, 82, 0.8);
    *:focus {
        outline: none;
        }
    }
}
