@import '../../App.scss';

.input-field {
    display: flex;
    flex-direction: column;
    font-family: $font_family;
    width: 100%;

    &__options {
        display: flex;
        flex-direction: row;
    }
}

.main-div {
    display: flex;
    flex-direction: row;
    justify-content: space-between;
    align-items: center;
    justify-content: space-between;
    font-family: $font_family;
    font-size: 16px;
    color: rgba(5, 5, 82, 0.80);
    width: 100%;
    padding: 16px;
}

.branding-element {
    width: 100%;
    margin-top: 12px;
    margin-bottom: 12px;
    display: flex;
    justify-content: center;
}

.branding-properties-div {
    display: flex;
    flex-direction: column;
}

.elements-list-div {
    height: 93vh;
    // overflow: scroll;
    border-right: solid 0.5px rgba(5, 5, 82, 0.1);
    padding: 8px;
    display: flex;
    flex-direction: column;
}

.elements-list {
    line-height: 31px;
    cursor: pointer;
    background: transparent;
    outline: none;
    border: none;
    padding-inline: 0;

    &__name {
        font-family: $font_family;
        color: rgba(5, 5, 82, 0.80);
        padding-inline: 12px;
        font-size: 14px;
        text-align: left;

        &:hover {
            border-radius: 5px;
            border: solid 0.5px rgba(5, 5, 82, 0.08);
            background-color: rgba(5, 5, 82, 0.04);
        }

        &_selected {
            font-family: $font_family;
            font-size: 14px;
            color: rgba(5, 5, 82, 0.80);
            border-radius: 5px;
            border: solid 0.5px rgba(5, 5, 82, 0.08);
            background-color: rgb(139 139 245 / 26%);

            &:hover {
                border-radius: 5px;
                border: solid 0.5px rgba(5, 5, 82, 0.08);
                background-color: rgb(139 139 245 / 26%);
            }
        }
    }
}

.edit-branding-properties {
    display: flex;
    flex-direction: row;
}