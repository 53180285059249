@import '../../App.scss';

.inputText {
    resize: none;
    box-sizing: border-box;
    width: 100%;
    border-radius: 0 0 5px 5px;
    border: 0.5px solid rgba(5, 5, 82, 0.20);
    border-top: none;
    padding: 12px;
    font-family: $font_family;
    font-size: 14px;
    color: rgba(5, 5, 82);

    &:focus {
        outline: none;
    }
}