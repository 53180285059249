@import '../../App.scss';

.module-builder-inputs-container{
  margin-top: 20px;
  display: flex;
  flex-direction: column;

  &-textfield{
    margin-bottom: 20px;
  }
  &-dropdown{
    margin-bottom: 20px;
  }
}
