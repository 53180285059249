@import '../../App.scss';

.condition {
  &_drawer {
    &__overlay {
      position: fixed;
      top: 0;
      left: 0;
      width: 100%;
      height: 100%;
      background-color: rgba(1, 1, 28, 0.4);
      z-index: 1100;
    }

    &__modal {
      position: absolute;
      top: 50%;
      left: 50%;
      transform: translate(-50%, -50%);
      border-radius: 4px;
      background-color: #ffff;
      z-index: 10000;
      overflow: auto;
      width: 800px;
      height: 600px;
      display: flex;
      flex-direction: column;
    }

    &__title {
      font-family: $font_family;
      font-size: 14px;
      font-weight: 500;
      color: #050552;
      display: flex;
      width: 100%;
      align-items: center;
      justify-content: space-between;
      height: 50px;
      border-bottom: 1px solid rgba(5, 5, 82, 0.1);
      padding: 8px 12px;


      &_save {
        color: white;
        font-family: $font_family;
        border-radius: 6px;
        height: 31px;
        background-color: #554ef1;
        border: none;
        margin-right: 12px;
        width: 60px;
        cursor: pointer;

        &:disabled {
          background-color: #564ef197;
          cursor: not-allowed;
        }
      }

      &_close {
        background-color: rgba(5, 5, 82, 0.05);
        height: 31px;
        width: 30px;
        border-radius: 8px;
        padding: 8px 10px;
        cursor: pointer;
        border: none;
        align-items: center;
      }
    }

    &__content {
      flex-grow: 1;
      padding-inline: 12px;
      padding-top: 8px;
    }

  }
}
