$custom_table_border_color: rgba(85, 78, 241, 0.1);
$custom_table_box_shadow_color: rgba(239, 239, 239, 0.25);
$custom_table_header_background_color: rgba(5, 5, 82, 0.06);
$custom_table_text_color: rgba(5, 5, 82, 1);
$custom_table_row_background_color: rgba(5, 5, 82, 0.02);
$custom_table_row_hover_background_color: rgba(5, 5, 82, 0.04);
$custom_table_cell_subtext_text_color: rgba(5, 5, 82, 0.5);
$custom_table_cell_border_bottom: 0.5px solid rgba(5, 5, 5, 0.1);
$border_grey: #fff;

.common__table {
    &_container {
        position: relative;
        white-space: nowrap;

        >.loader_container {
            position: absolute;
            top: 50%;
            left: 50%;
            transform: translate(-100%, -0%);
        }
    }

    &_container:hover {
        cursor: pointer;
      }

    &_pagination{
        padding: 10px;
        text-align: center;
        align-items: center;
    }

    &_overflow {
        position: relative;
    }

    &_wrapper {
        width: 100%;
        overflow-x: auto;

        >table {
            border-collapse: separate !important;
            border-spacing: 0;
            border-radius: 5px;
            width: 100%;
            height: 100%;
            border: solid 1px $custom_table_border_color;
            box-shadow: 0 0 15px 0 $custom_table_box_shadow_color;

            &.emptyLoading {

                & th,
                td {
                    color: transparent !important;
                }
            }

            &.empty {

                td {
                    color: transparent !important;
                }

                tbody {
                    position: relative;

                    >span {
                        color: $custom_table_text_color;
                        position: absolute;
                        top: 50%;
                        left: 50%;
                        transform: translate(-50%, -50%);
                    }
                }
            }

            &.loading {
                & td {
                    opacity: 0.3;
                }
            }

            tr:not(:last-child)>td,
            th {
                border-bottom: $custom_table_cell_border_bottom;
            }

            >thead {
                >tr {
                    >th {
                        padding: 20px 20px;
                        text-align: left;
                        color: $custom_table_text_color;
                        font-size: 14px;
                        font-weight: 600;
                        background-color: $custom_table_header_background_color;

                        &:first-child {
                            border-top-left-radius: 5px;
                        }

                        &:last-child {
                            border-top-right-radius: 5px;
                        }
                    }
                }
            }

            >tbody {
                >tr {
                    >td {
                        padding: 20px 20px;
                        text-align: left;
                        color: $custom_table_text_color;
                        font-size: 14px;
                    }

                    &:nth-child(even) {
                        background-color: $custom_table_row_background_color;
                    }

                    &:nth-child(odd) {
                        background-color: transparent;
                    }

                    &:hover {
                        background-color: $custom_table_row_hover_background_color;
                    }
                }
            }
        }
    }
}